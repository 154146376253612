$worst: #00516c;
$bad: #008495;
$neutral: #00c7d5;
$good: #00fffd;
$best: #c9ffff;
$notApplicable: #bbc0d2;
$missing: rgba(0, 0, 0, 0);

$background: #1c1536;
$active: #ff6878;
$activeSecondary: #00fffd;

$minAppWidthDesktop: 1125px;
$minAppWidtMobile: 325px;

$easeDuration: .3s;

$color-range: (
  worst $worst,
  bad $bad,
  neutral $neutral,
  good $good,
  best $best,
);

:export {
  worst: $worst;
  bad: $bad;
  neutral: $neutral;
  good: $good;
  best: $best;
  missing: $missing;
  notApplicable: $notApplicable;
}
