.select-state-modal {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(242, 244, 246, 0.96);
  color: #1c1536;
  transition: opacity $easeDuration ease;
  padding: 0;

  &.force-fade {
    opacity: 0;
  }

  &.raised {
    z-index: 10000;
  }


  & .select-state-modal_wrapper {
    display: flex;
    max-width: 1550px;
    margin: auto;
    position: relative;
    padding: 20.6vh 0 100px;
    @media only screen and (max-height: 800px) {
      padding-top: 7.5%;
    }

    & .select-state-modal_close-button {
      text-align: right;
      margin-top: 57px;
      margin-right: 83px;
      font-family: Plaak5Trial-45-A;
      font-size: 12px;
      font-weight: bold;
      line-height: 0.83;
      letter-spacing: 1.2px;
      color: #1c1536;

      & .is-desktop {
        width: 73px;
        height: 14px;
        text-align: left;
        line-height: 13px;
        transition: color $easeDuration ease;
        display: flex;

        & img {
          position: absolute;
          right: 0;
          margin-left: 21px;
          transition: opacity $easeDuration ease;

          &.is-hovered {
            opacity: 0;
          }
        }

        &:hover {
          color: $active;

          & img {
      
            &.is-hovered {
              opacity: 1;
            }
          }
        }
      }
    }

    & .select-state-modal_content {
      margin: 0 auto;
      padding-left: 26px;

      & .select-state-modal_title {
        margin-bottom: 18px;
        font-family: Plaak5Trial-45-A;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.57;
        letter-spacing: 1.75px;
      }
    
      & .select-state-modal_states {
        font-family: Steradian;
        font-size: 16px;
        line-height: 1.25;
        display: flex;
        height: 470px;
        flex-wrap: wrap;
        flex-direction: column;
        width: 852.5px;
    
        & > div {
          width: 170.5px;
    
          & button {
            font-family: Steradian;
            font-size: 16px;
            line-height: 1.25;
            color: #1c1536;
            padding: 0;
            margin: 0;
            margin-top: 18px;
            text-align: left;
            width: 90%;
      
            &:hover {
              color: $active;
            }
          }
        }
      }

      // 4 columns
      @include breakpoint(mediumLarge down) {
        & .select-state-modal_states {
          height: 550px;
          width: 682px;
        }
      }

      // 3 columns
      @include breakpoint(medium down) {
        & .select-state-modal_states {
          height: 726px;
          width: 511.5px;
        }
      }
    }
  }

  @include breakpoint(small down) {

    & .select-state-modal_wrapper {
      padding: 42px 7.9% 1.5% 1%;

      & .select-state-modal_close-button {
        margin: 16px 8px 0 0;
      }

      & .select-state-modal_content {
        margin: 0;
        width: 100%;

        & .select-state-modal_title {
          text-align: center;
          margin-bottom: 21px;
        }

        & .select-state-modal_search {
          height: 50px;
          border-radius: 25px;
          font-family: Steradian;
          font-size: 16px;
          line-height: 1.5;
          color: #000000;
          padding: 0 23px;
          margin-bottom: 34px;
          border: none;
          background-image: url('../images/search.svg');
          background-repeat: no-repeat;
          background-position: right;
          background-origin: content-box;
        }

        & .select-state-modal_states {
          height: unset;
          width: unset;

          & > div {
            width: 100%;

            & > button {
              width: 100%;
              border-bottom: 1px solid black;
              padding: 13px 0;
              margin: 0;

              &:hover {
                color: unset;
              }
      
              &::after {
                content: 'View Profile';
                position: absolute;
                right: 36px;
                font-family: Plaak5Trial-45-A;
                color: $active;
                font-size: 12px;
                letter-spacing: 1.5px;
                padding-top: 3px;
              }
            }

            &:last-of-type {
              & > button {
                border: none;
              }
            }
          } 
        }
      }
    }
  }
}
