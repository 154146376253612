.footer {
	height: 347px;
	padding: 73px 231px 0 103px;
	display: flex;
	justify-content: space-between;
	color: #ffffff;
	width: 100%;
	max-width: 1800px;
	margin: auto;

	& .footer_left, & .footer_right {
		display: flex;
		flex-direction: column;

		& .footer_title {
			margin-bottom: 115px;

			& a {
				display: flex;
	
				& .footer_title_text {
					font-family: StyreneB;
					width: 171px;
					height: 36px;
					font-size: 16px;
					line-height: 1.13;
					color: #ffffff;
					margin-left: 17px;
				}
			}
		}
	
		& .footer_attribution {
			& a {
				font-family: Plaak5Trial-45-A;
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 1.5px;
				transition: color $easeDuration ease;
				color: #ffffff;

				&:hover {
					color: $active;
				}
			}

			& .footer_attribution_copyright {
				margin-top: 10px;
				font-family: Steradian;
				font-size: 12px;
				line-height: 1.67;
				font-weight: normal;
				letter-spacing: -.06px;

				& .footer_attribution_copyright_spacer {
					margin-left: 6px;
					margin-right: 5px;
				}

				& a {
					font-family: Steradian;
					font-size: 12px;
					line-height: 1.67;
					font-weight: normal;
					letter-spacing: -.06px;
				}
			}
		}

		& .footer_address {
			font-family: Steradian;
			font-size: 15px;
			line-height: 1.6;
		}

		& .footer_contact {
			font-family: Steradian;
			font-size: 15px;
			line-height: 1.6;
			margin-top: 38px;

			& a {
				color: #ffffff;
				transition: color $easeDuration ease;

				&:hover {
					color: $active;
				}
			}
		}

		& .footer_social {
			margin-top: 42px;
			display: flex;

			& a {
				margin-right: 11px;
				width: 24px;
				height: 24px;
				display: block;
				position: relative;

				img {
					transition: opacity $easeDuration ease;
					position: absolute;
					left: 0;
					width: 24px;
				  height: 24px;

					&.is-hover {
						opacity: 0;
					}
				}

				&:hover img {

					&.is-hover {
						opacity: 1;
					}
				}
			}
		}
	}

	& .footer_right {
		margin-right: -20px;
	}

	@include breakpoint(small down) {
		padding: 11% 0 9.5% 8.7%;
		flex-direction: column;
		height: unset;

		& .footer_left, & .footer_right {
			margin: 0;

			& .footer_title, .footer_social, & .footer_contact, & .footer_address, & .footer_attribution {
				display: block;
				margin-top: 0;
			}

			& .footer_title {
				margin-bottom: 73px;
			}

			& .footer_address {
				margin-bottom: 33px;
				line-height: 1.7;
			}

			& .footer_contact {
				margin-bottom: 40px;

				& a:hover {
					color: #ffffff;
				}
			}

			& .footer_social {
				display: flex;
				margin-bottom: 77px;

				& a {
					margin-right: 12px;

					&:hover img.is-hover {
						opacity: 0;
					}
				}
			}

			& .footer_attribution {
				font-family: Plaak5Trial-45-A;
				font-size: 12px;
				font-weight: bold;
				letter-spacing: 1.5px;

				& a:hover {
					color: #ffffff;
				}

				& .footer_attribution_copyright {
					width: 80%;
				}
			}
		}
	}
}
