.rank {
	padding: 66px 15.8% 100px 15.6%;
	background-color: $background;
	max-width: 1550px;
	margin: auto;
	
	& .rank_no-metrics-warning {
		width: 500px;
		padding-top: 15%;
		font-family: StyreneB;
		font-size: 30px;
		line-height: normal;
		text-align: center;
		color: #b1bad5;
		margin: auto;
		height: calc(100vh - 89px - 95px - 166px); // header - navbar - padding on rank view
	}

	& .rank_section {

		& .rank_section_title {
			background-color: $background;
			border-bottom: solid 1px $activeSecondary;
			display: flex;
			justify-content: space-between;
			font-family: StyreneB;
			font-size: 26px;
			color: $activeSecondary;
			padding-bottom: 8px;
			height: 44px;
			transition: max-width 300ms ease;

			& .rank_section_title_overlay {
				position: absolute;
				top: 0;
				bottom: -1px;
				left: 0;
				right: 0;
				background: $background;
				opacity: 0;
				pointer-events: none;
				z-index: 1;

				&.is-active {
					opacity: .5;
				}
			}

			& .rank_section_title_percentage {
				position: absolute;
				left: 0;
				text-transform: uppercase;
				display: flex;
				align-items: center;

				& span {
					font-size: 14px;
					margin: 3px 0 0 11px;
					font-family: Plaak5Trial-45-A;
					font-weight: bold;
					letter-spacing: 1.75px;
				}
			}

			& .rank_section_title_buttons {
				display: none;
				align-items: center;
				padding: 0 3px 3px 0;
				margin-top: 10px;
				z-index: 2;

				& a {
					font-family: Plaak5Trial-45-A;
					font-size: 11px;
					font-weight: bold;
					line-height: 2;
					letter-spacing: 1.38px;
					color: #ffffff;
					margin-bottom: 0;
					margin-left: 33px;
					transition: color $easeDuration ease;

					&:hover {
						color: $active;
					}
				}
			}

			&.is-stuck, &.is-first, &.is-at-bottom {

				& .rank_section_title_buttons {
					display: flex;
				}
			}

			&.is-stuck {
				padding-top: 3px;
			}
		}

		& .rank_section_no-states {
			height: 127px;
			background-color: #312b44;
			display: flex;
			justify-content: space-between;
			margin: 55px -30px;
			padding: 0 30px;
			align-items: center;

			& .rank_section_no-states-title {
				font-family: StyreneB;
				font-size: 26px;
				color: #b1bad5;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				width: 30.9%;

				& span {
					font-size: 14px;
					margin: 3px 0 0 11px;
					font-family: Plaak5Trial-45-A;
					font-weight: bold;
					letter-spacing: 1.75px;
				}
			}

			& .sticky-container {
				width: 30.9%;

				& .rank_section_no-states-title {
					width: auto;
				}
			}

			& .rank_section_no-states-details {
				font-family: Steradian;
				font-size: 16px;
				line-height: 2.06;
				color: #b1bad5;
				flex: 1;
				padding-top: 12px;
				text-align: left;
			}
		}

		& .rank_section_states {
			margin: 36px 0 0 18px;
			padding-bottom: 52px;

			& .rank_section_state {
				display: flex;
				width: 100%;
				transition: opacity $easeDuration ease;

				& .rank_section_state_bar-and-rank {
					flex: 1;
					display: flex;
					justify-content: flex-start;

					& .rank_section_state_rank {
						width: 33px;
						font-family: StyreneB;
						font-size: 16px;
						font-weight: 500;
						line-height: 2.06;
						text-align: right;
						color: $active;
						margin-left: 5px;
						margin-right: 30px;
					}

					& .rank_section_state_bar, & .rank_section_state_bar-short {
						height: 34px;
						margin-bottom: 11px;
						background-color: #525971;
						font-family: StyreneB;
						font-size: 26px;
						line-height: 1.2;
						color: #ffffff;
						white-space: nowrap;
						overflow: visible;
						text-align: left;
						padding-left: 8px;
						cursor: pointer;
						
						&.is-zero-width {
							background: linear-gradient(to right, #525971 50%, $background 50% );
						}
					}

					& .rank_section_state_bar-short {
						padding-top: 4.5px;
						font-size: 18px;
						display: none;
					}
				}

				& .rank_section_state_percentage {
					font-family: 'Space Mono', monospaces;
					font-size: 16px;
					line-height: 2.13;
					color: $activeSecondary;
					padding-right: 2%;
					padding-left: 2%;
					width: 9%;
					text-align: left;
				}

				& .rank_section_state_other-years {
					position: relative;
					font-family: Steradian;
					font-size: 10px;
					font-weight: 500;
					line-height: 1.4;
					letter-spacing: 0.22px;
					color: #c4f0ff;
					opacity: 0;
					transition: opacity $easeDuration ease;

					& > div {
						position: absolute;
						right: -108px;
						top: 3px;
						width: 100px;
						height: 34px;
						justify-content: center;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
					}
				}

				&.other-is-hovered {
					opacity: .5;
				}

				&.is-hovered {
					& .rank_section_state_other-years {
						opacity: 1;
					}
				}
			}
		}
	}

	& .rank_compare-wrapper {
		display: flex;
		justify-content: space-between;
		min-width: 795px;
		padding-top: 3px;

		& .rank_compare-column {
			width: 40%;
			min-width: 340px;

			&:first-of-type {
				margin-left: -10px;
			}
			
			&:last-of-type {
				margin-right: 0;
			}

			& .rank_compare-column_title {
				font-family: StyreneB;
				font-size: 36px;
				line-height: 0.89;
				text-align: center;
				color: #ffffff;
				padding-bottom: 37px;
			}

			& .rank_compare-column_states {
			
				& .rank_compare-column_state {
					// prevent margin collapsing
					// and let hover flow from one item to the netx
					margin-bottom: -1px;
					padding-bottom: 1px;
					margin-top: -1px;
    			padding-top: 1px;
					cursor: pointer;
					margin-right: -9px;

					& > div {
						display: flex;
						align-items: center;
						margin: 5.5px 10px 5.5px 0;
						transition: background-color $easeDuration ease, opacity $easeDuration ease;

						& .rank_compare-column_state_rank-and-name {
							display: flex;
							flex: 1;
							justify-content: flex-start;
							align-items: center;

							& .rank_compare-column_state_rank {
								width: 22px;
								font-family: StyreneB;
								font-size: 14px;
								font-weight: 500;
								line-height: 2.06;
								text-align: right;
								color: $active;
								margin-left: 11px;
								margin-right: 13px;
							}
		
							& .rank_compare-column_state_name, & .rank_compare-column_state_abbv {
								font-family: StyreneB;
								font-size: 22px;
								line-height: 1;
    						padding-bottom: 3px;
								color: #ffffff;
								white-space: nowrap;
								text-align: left;
								padding-left: 12px;
							}

							& .rank_compare-column_state_abbv {
								display: none;
							}
						}

						& .rank_compare-column_state_percentage {
							font-family: 'Space Mono', monospaces;
							font-size: 14px;
							line-height: 2.13;
							color: $activeSecondary;
							padding-right: 18px;
							padding-top: 2px;
    					padding-bottom: 2px;
						}

						& .rank_compare-column_state_other-year {
							position: relative;
							font-family: Steradian;
							font-size: 10px;
							font-weight: 500;
							line-height: 1.4;
							letter-spacing: 0.22px;
							color: #c4f0ff;
							opacity: 0;
							transition: opacity $easeDuration ease;

							& > div {
								position: absolute;
								display: flex;
								right: -137px;
								width: 120px;
								height: 100%;
								align-items: center;

								& img {
									padding-right: 9px;
								}
							}
						}
					}

					&.other-is-hovered {
						& > div {
							opacity: .5;
						}
					}

					&.is-hovered {
						& > div {
							background-color: #525971;
							& .rank_compare-column_state_other-year {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	@include breakpoint(small down) {
		margin: 12px 36px 20px 30px;
		padding: 0;
		min-width: 280px;

		& .rank_no-metrics-warning {
			width: initial;
			font-size: 20px;
			height: calc(100vh - 89px - 95px - 22px);
		}

		& .rank_description {
			padding-top: 20px;
			font-family: Steradian;
			font-size: 16px;
			line-height: 1.5;
			color: #b1bad5;
			text-align: center;
			margin: 0 6%;
			min-height: 100px;

			& .rank_metrics-warning {
				width: 210px;
				margin: 16px auto;
				font-family: Steradian;
				font-size: 12px;
				line-height: 1.58;
				text-align: center;
				color: #b1bad5;
			}
		}

		& .rank_section {

			& .rank_section_title {
				padding-bottom: 8px;
				height: unset;

				&.is-stuck {
					padding-top: 8px;
				}

				// hide the overflow letters as we scroll from behind
				&::before {
					content: '';
					width: 10px;
					height: 120%;
					position: absolute;
					bottom: -2px;
					left: -10px;
					background: $background;
				}

				& .rank_section_title_percentage {
					font-size: 20px;
					position: unset;

					& span {
						font-size: 12px;
						margin: 5px 0 0 9px;
					}
				}
			}

			& .rank_section_no-states {
				height: unset;
				padding: 0;
				margin: 30px 0;
				justify-content: space-evenly;

				& .sticky-container {
					width: 110px;
					display: flex;

					& .rank_section_no-states-title {
						width: 110px;
					}
				}
	
				& .rank_section_no-states-title {
					font-size: 18px;
					width: 110px;
					padding-right: 0;

					& span {
						font-size: 10px;
						margin-left: 5px;
						margin-top: 1px;
					}
				}
	
				& .rank_section_no-states-details {
					font-size: 10px;
					line-height: 1.5;
					padding: 12px 0;
					width: 151px;
					text-align: center;
					flex: unset;
				}
			}

			& .rank_section_states {
				margin-top: 36px;
				margin-left: 0;
				padding-bottom: 29px;

				& .rank_section_state {

					& .rank_section_state_bar-and-rank {
						margin-right: 10px;

						& .rank_section_state_rank {
							margin-left: -7px;
							margin-right: 19px;
							font-size: 14px;
							width: 18px;
							line-height: 1.6;
						}

						& .rank_section_state_bar {
							display: none;
						}
	
						& .rank_section_state_bar-short {
							display: block;
							height: 24px;
							margin-bottom: 14px;
							font-size: 20px;
							padding-left: 12px;
							padding-top: 0;
							line-height: 1.1;
						}
					}

					& .rank_section_state_percentage {
						font-size: 14px;
						line-height: 1.65;
						padding-right: 10px;
						padding-left: 0;
						width: 35px;
					}

					&.other-is-hovered {
						opacity: 1;
					}
				}
			}
		}

		& .rank_compare-wrapper {
			min-width: unset;
			padding-top: 29px;
	
			& .rank_compare-column {
				min-width: unset;
				width: 45%;

				&:first-of-type {
					margin-left: -7px;
				}
				
				&:last-of-type {
					margin-right: -11px;
				}

				& .rank_compare-column_title {
					font-size: 24px;
					padding-bottom: 15px;
				}

				& .rank_compare-column_states {
				
					& .rank_compare-column_state {

						& > div {
							margin: 4.5px 10px 0;
	
							& .rank_compare-column_state_rank-and-name {
	
								& .rank_compare-column_state_rank {
									width: 20px;
									margin-right: 10px;
									margin-left: 0;
									margin-bottom: 3px;
								}
			
								& .rank_compare-column_state_name {
									display: none;
								}

								& .rank_compare-column_state_abbv {
									display: block;
									line-height: 1.4;
									padding-bottom: 2.7px;
								}
							}

							& .rank_compare-column_state_percentage {
								padding-right: 0;
								margin-top: -4px;
								padding-top: 0;
								padding-bottom: 0;
							}
						}

						&.other-is-hovered {
							& > div {
								opacity:1;
							}
						}
	
						&.is-hovered {
							& > div {
								background-color: $background;
							}
						}
					}
				}
			}
		}
	}
}
