
.scatter-plot-tooltip {
	color: #ffffff;
	display: flex;
	flex-direction: column;
	text-align: center;
	font-family: StyreneB;
	position: absolute;
	z-index: 10;
	pointer-events: none;
	visibility: hidden;
	width: 200px;
	align-items: center;
	transition: opacity $easeDuration ease, visibility $easeDuration ease;

	& .scatter-plot-tooltip_title {
		font-family: StyreneB;
		font-size: 16px;
		text-align: center;
		color: #ffffff;
		white-space: nowrap;
		margin-bottom: 6px
	}

	& .scatter-plot-tooltip_details {
		font-family: Steradian;
		font-size: 10px;
		font-weight: 500;
		line-height: 1.4;
		letter-spacing: 0.22px;
		color: #c4f0ff;
	}
}
