@keyframes ldio-wg75lf948f {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(0.6900000000000001,0.6900000000000001);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.ldio-wg75lf948f div > div {
  position: absolute;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: #00fffd;
  animation: ldio-wg75lf948f 1.36986301369863s linear infinite;
}.ldio-wg75lf948f div:nth-child(1) > div {
  left: 19px;
  top: 19px;
  animation-delay: 0s;
}
.ldio-wg75lf948f > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 50px 50px;
}
.loadingio-spinner-spin-200ogfyedcs {
  width: 91px;
  height: 91px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-wg75lf948f {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.91);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-wg75lf948f div { box-sizing: content-box; }
/* generated by https://loading.io/ */