.indicators {
	padding-top: 83px;
	position: relative;
	max-width: 1400px;
	margin: auto;

	& .indicators-group {
		margin: 0 105px 3% 102px;

		& .indicators-group_title {
			font-family: StyreneB;
			font-size: 20px;
			line-height: 0.8;
			color: #ffffff;
			text-align: left;
			margin: 0 2px 48px;
			padding-top: 25px;
			border-top: 1px solid $active;
		}

		& .cell {

			&:nth-of-type(even) {
				& .indicator-card {
					padding-left: 13px;
					margin-right: -13px;

					& .indicator-card_title, & .indicator-card_view-indicator-button {
						margin-right: 13px;
					}
	
					& .indicator-card_description {
						padding-left: 13px;
						padding-right: 13px
					}
	
					& .indicator-card_visualization {
						margin: 0 40px 0 27px;
					}
				}
			}

			&:nth-of-type(odd) {
				& .indicator-card {
					padding-right: 13px;
					margin-left: -13px;

					& .indicator-card_title, & .indicator-card_view-indicator-button {
						margin-left: 13px;
					}
	
					& .indicator-card_description {
						padding-left: 26px;
					}
	
					& .indicator-card_visualization {
						margin: 0 27px 0 40px;
					}
				}
			}

			& .indicator-card_background-overlay {
				background: $background;
				opacity: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				pointer-events: none;
				z-index: 2;
				transition: opacity $easeDuration ease;
			}

			& .indicator-card {
				padding: 19px 0 31px 0;
				display: flex;
				margin-bottom: 50px;
				flex-direction: column;
				justify-content: space-between;
				z-index: 1;
				position: relative;
				transition: all $easeDuration ease;
				cursor: pointer;

				& .indicator-card_title {
					font-family: StyreneB;
					font-size: 26px;
					text-align: center;
					color: #ffffff;
					margin: 12px 0 19px;
					line-height: 34px;
				}
		
				& .indicator-card_description {
					font-family: Steradian;
					font-size: 13px;
					text-align: center;
					color: #ffffff;
					margin: 0 0 19px;
					line-height: 21px;
				}
		
				& .indicator-card_view-indicator-button {
					font-family: Plaak5Trial-45-A;
					font-size: 12px;
					font-weight: bold;
					line-height: 0.83;
					letter-spacing: 1.2px;
					color: #ffffff;
					border-bottom: solid 1px $activeSecondary;
					padding: 0 0 11px 0;
					margin-bottom: 61px;
					transition: color $easeDuration ease;
					
					&:hover {
						color: $activeSecondary;
					}
				}
			}

			& .indicator-not-available-card {
				background-color: #312b44;
				color: #7e8896;
				margin: 6.2% 0 0 8.3%;
				height: 73%;
				width: 87%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				min-height: 263px;

				& .indicator-card_title {
					font-family: StyreneB;
					font-size: 26px;
					margin: 5px 6px 20px 0;
				}
		
				& .indicator-card_description {
					font-family: Steradian;
					font-size: 13px;
					line-height: 1.54;
					width: 61%;
					margin-right: 6px;
				}
			}

			&:hover {
				& .indicator-card {
					box-shadow: 0 2px 6px 0 rgba(28, 21, 54, 0.61);
					background-color: #525971;
					z-index: 3;
				}

				& .indicator-card_background-overlay {
					opacity: .5;
				}
			}
		}
	}

	@include breakpoint(small down) {
		margin: 12px 6.7% 0 5.5%;
		padding-top: 0;

		& .indicator_description {
			padding-top: 20px;
			font-family: Steradian;
			font-size: 16px;
			line-height: 1.5;
			color: #b1bad5;
			text-align: center;
			margin: 0 3% 50px;
		}

		& .indicators-group {
			margin: 0 0 44px 0;

			& .indicators-group_title {
				padding-top: 12px;
				margin: 0 6px 38px;
				line-height: 1.3;
			}

			& .cell {

				&:nth-of-type(even), &:nth-of-type(odd) {

					& .indicator-card {
						padding: 0;
						margin: 0;
						width: unset;

						& .indicator-card_title {
							font-size: 22px;
							margin: 11px 15px 13px;
						}

						& .indicator-card_description {
							padding: 0 25px;

							& > p {
								line-height: 1.7;
							}
						}

						& .indicator-card_visualization {
							margin: 22px 0 8px;

							& .indicator-card_visualization_title {
								font-family: Plaak5Trial-45-A;
								font-size: 10px;
								font-weight: bold;
								line-height: 1.6;
								letter-spacing: 0.71px;
								color: #b1bad5;
							}

							& .indicator-card_relative-to-average {
								font-family: Steradian;
								font-size: 10px;
								font-weight: 500;
								line-height: 1.6;
								color: #b1bad5;
								width: 180px;
								margin: 0 auto 23px;
	
								& > div {
									margin-bottom: 1em;
								}
							}
						}

						& .indicator-card_view-indicator-button {
							margin: 6px 0 57px;

							&:hover {
								color: #ffffff;
							}
						}
					}

					& .indicator-not-available-card {
						height: unset;
						padding: 20px 0 30px;
						margin: 10px 0 40px;
						width: unset;
						margin-left: 1%;
    				padding-left: 1%;
						min-height: unset;
					}
				}

				&:hover {
					& .indicator-card {
						box-shadow: unset;
						background-color: inherit;
						z-index: unset;
					}
	
					& .indicator-card_background-overlay {
						opacity: 0;
					}
				}
			}
		}
	}
}
