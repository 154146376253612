@import '../../../node_modules/foundation-sites/scss/foundation';
@import './_settings.scss';
@include foundation-everything;
@import './Variables.scss';
@import '../../../node_modules/awesome-foundation6-checkbox/src/awesome-foundation6-checkbox.scss';

@font-face {
  font-family: 'Plaak5Trial-45-A';
  font-weight: bold;
  src: url('../fonts/Plaak/Plaak - 45A-Foch-BoldA-205TF.woff2') format('woff2'),
       url('../fonts/Plaak/Plaak - 45A-Foch-BoldA-205TF.woff') format('woff');
}
@font-face {
  font-family: 'Steradian';
  src: url('../fonts/Steradian/Steradian\ Regular.otf') format("opentype");
}
@font-face {
  font-family: 'Steradian';
  font-weight: 500;
  src: url('../fonts/Steradian/Steradian\ Medium.otf') format("opentype");
}
@font-face {
  font-family: 'StyreneB';
  src: url('../fonts/StyreneB/StyreneB-Regular.otf') format("opentype");
}
@font-face {
  font-family: 'StyreneB';
  font-weight: 500;
  src: url('../fonts/StyreneB/StyreneB-Medium.otf') format("opentype");
}

@include breakpoint(small down) {
  .is-desktop {
    display: none !important;
  }
}

@include breakpoint(medium up) {
  .is-mobile {
    display: none !important;
  }
}

html, body {
  margin: 0;
  padding: 0;
  background-color: $background;

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & .apostraphe {
    font-family: LucidaGrande !important;
  }  

  @include breakpoint(medium up) {
    min-width: $minAppWidthDesktop;
  }
  @include breakpoint(small down) {
    min-width: $minAppWidtMobile;
  }
}

.l-main {
  height: 100%;
  text-align: center;
  color: #ffffff;
  min-height: 100px;

  & .fade-enter {
    & .map > :not(.nav-filter-bar-wrapper), & .indicators, & .rank {
      opacity: 0;
    }
  }
  & .fade-enter.fade-enter-active {
    & .map > :not(.nav-filter-bar-wrapper), & .indicators, & .rank {
      opacity: 1;
      transition: opacity 250ms ease-in;
    }
  }
  & .fade-exit {
    & .map :not(.nav-filter-bar-wrapper), & .indicators, & .rank {
      opacity: 1;
    }
  }
  & .fade-exit.fade-exit-active {
    & .map > :not(.nav-filter-bar-wrapper), & .indicators, & .rank {
      opacity: 0;
      transition: opacity 250ms ease-out;
    }
  }


  & .loading {
    height: calc(100vh - 89px);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 150px;
  }
}

@import './Loader.scss';
@import './Header.scss';
@import './Map.scss';
@import './NavFilterBar.scss';
@import './MetricsDrawer.scss';
@import './LandingContent.scss';
@import './Footer.scss';
@import './HeatmapTooltip.scss';
@import './ScatterPlotTooltip.scss';
@import './SelectAState.scss';
@import './StateProfile.scss';
@import './Rank.scss';
@import './Indicators.scss';
@import './IndicatorProfile.scss';
@import './SingleIndicatorVisual.scss';
@import './IndicatorVsNation.scss';
@import './AppFailure.scss';

/* Foundation Overrides */

.js-off-canvas-overlay.is-visible {
  opacity: 0;
  cursor: inherit;
}

.title-bar {
  background-color: inherit;
}

.top-bar {
  background-color: inherit;
}

.top-bar ul {
  background-color: inherit;
}

.top-bar .top-bar-left {
  flex: 0;
}

.dropdown.menu>li>a{
  padding: 0;
  padding-bottom: 3px;
}

:focus {
  outline: none;
}
.grid-padding-x > .cell {
  padding: 0;
}

.has-tip {
  margin: 0;
  border: none;
  cursor: initial;
  font-weight: initial;
  display: initial;
}

@include breakpoint(medium up) {
  .zf-has-scroll.is-reveal-open {
    overflow-y: hidden !important;
  }
}

@include breakpoint(small down) {
  html.is-reveal-open {
    position: initial !important;
  }
}

