.header {
	padding: 28px 106px 19px 100px;
	max-width: 1800px;
	margin: auto;

	& .header_left {
		& a {
			display: flex;

			& .header_left_title {
				font-family: StyreneB;
				width: 171px;
				height: 36px;
				font-size: 16px;
				line-height: 1.13;
				color: #ffffff;
				margin-left: 18px;
				white-space: nowrap;
			}

			& img {
				max-width: initial;
				vertical-align: initial;
			}
    }
  }

  & .header_right {
		margin-top: -10px;

		& ul > li:last-of-type {
			margin-left: 38px;
		}

		& ul > li {
			position: relative;
			margin-left: 41px;

			& a {
				color: #ffffff;
				font-family: Plaak5Trial-45-A;
				font-size: 12px;
				font-weight: bold;
				line-height: 1.83;
				letter-spacing: 1.5px;
				border-bottom: 2px solid rgba(0, 0, 0, 0);
				position: relative;
				padding: 0;
				transition: all $easeDuration ease;

				&::after {
					content: '';
					width: 1.25px;
					height: 4px;
					position: absolute;
					bottom: -2px;
					right: 0;
					background: $background;
				}

				&:hover {
					color: $active;
					border-bottom: 2px solid rgba(0, 0, 0, 0);
				}

				&.is_active {
					border-bottom: 2px solid $active;
				}

				&.is_active:hover {
					color: $active;
				}
			}
		}
	}
	
	@include breakpoint(small down) {
		flex-wrap: nowrap;
		margin: 27px 34px 33px;
		justify-content: space-between;
		padding: 0;

		& .header_left_title {
			display: none;
		}

		& .header_right {
			flex: 0;
			flex-basis: 60%;

			& ul {
				flex-wrap: nowrap;
				margin-left: 6%;
				justify-content: flex-end;
		
				& > li, > li:last-of-type {
					margin-left: 11%;

					a {
						font-size: 14px;
						top: -1px;
						padding-bottom: 2px;

						&:hover {
							color: #ffffff;
						}
		
						&.is_active {
							border-bottom: 2px solid $active;
						}
		
						&.is_active:hover {
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}