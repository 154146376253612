.nav-filter-bar-wrapper {
	max-width: 1800px;
	margin: auto;
	width: 100%;
}

.nav-filter-bar {
  margin: 0 105px 5px 102px;

	& .nav-filter-bar_prompts {
		font-family: Steradian;
    font-size: 13px;
    line-height: 1.46;
    color: #b1bad5;
		text-align: left;
		display: flex;
		justify-content: flex-start;

		& > div {
			width: 398px;
			margin-right: 42px;
		}
	}

  & .nav-filter-bar_content {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    align-items: flex-end;
  }

  & .nav-filter-bar_display-options {
		margin: 0;
		display: flex;

		& .nav-filter-bar_display-option {
			width: 114px;
			height: 26px;
			border: solid 1px #525971;
			border-right: none;
			font-family: Plaak5Trial-45-A;
			font-size: 14px;
			font-weight: bold;
			padding: 0;
			letter-spacing: 1.75px;
			text-align: center;
			background-color: inherit;
			display: block;
			margin: 0;
			line-height: 1;

			&:last-of-type {
				border-right: solid 1px #525971;

				& a {
					padding-left: 2px;
				}
			}

			& a {
				color: #ffffff;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-around;
				transition: background-color $easeDuration ease;

				&:hover {
					background-color: rgba(82, 89, 113, 0.5);
				}

				&.is_active {
					background-color: #525971;
				}
			}
		}
	}

  & .nav-filter-bar_filter {
		display: flex;

		&.is-comparing button.nav-filter-bar_year-filter-button {
			padding-right: 16px;
			padding-left: 0;

			&::before {
				margin-left: 16px;
			}
		}
		
		& button {
			white-space: nowrap;
			background-color: #525971;
      font-family: StyreneB;
      font-size: 17px;
      line-height: 1.29;
      color: #ffffff;
      padding: 0;
      margin: 0;
			height: 36px;
			border-radius: 20px;
			padding-bottom: 3px;

			&:hover, &.hover {
				background-color: rgba(82, 89, 113, 0.5);
			}

			&.nav-filter-bar_metric-filter-button {
				width: 210px;
				margin-left: 22px;

				& img {
					margin-right: 9px;
					position: relative;
					top: -1px;
				}

				&.is-disabled {
					background-color: #312b44;
					color: #7e8896;
					opacity: 1;
					cursor: default;
				}

				&.is-filtered {
					background-color: #ffffff;
					color: #000000;
					&::before {
						border-color: #000000 transparent transparent;
					}
				}
			}
	
			&.nav-filter-bar_year-filter-button {
				padding-right: 26px;
				padding-left: 7px;
				text-align: left;

				&::before {
					width: 0;
					height: 0;
					border: inset 6px;
					content: '';
					border-bottom-width: 0;
					border-top-style: solid;
					border-color: $activeSecondary transparent transparent;
					position: relative;
					top: 10px;
					float: left;
					margin-left: 13px;
					margin-right: 10px;
				}

				& span.is-small-screen {
					display: none;
				}
			}
		}

		& .nav-filter-bar_year-filter {
			width: 477px;
			background: inherit;
			border: none;
			padding: 0;

			& .nav-filter-bar_year-filter-contents {
				width: 100%;
				padding: 37px 29px 15px 31px;
				font-family: Steradian;
				font-size: 14px;
				font-weight: 500;
				line-height: 1.29;
				color: #ffffff;
				border-radius: 20px;
				box-shadow: 0 2px 6px 0 rgba(28, 21, 54, 0.61);
				background-color: #525971;
				display: flex;
				flex-direction: column;
				text-align: left;

				&.is-short {
					padding-top: 27px;
					padding-bottom: 0;
				}

				& .nav-filter-bar_year-filter-contents_close {
					display: none;
				}

				& .nav-filter-bar_choose-year {
					display: flex;
					justify-content: flex-start;
					margin-top: 12px;
					flex-wrap: wrap;
					margin-bottom: 19px;

					button {
						margin-right: 12px;
						margin-bottom: 10px;
						width: 49px;
						height: 28px;
						border-radius: 14px;
						border: solid 1px #ffffff;
						background-color: inherit;
						font-size: 14px;
						padding: 1px 0 3px 0;
						letter-spacing: -0.7px;
					  transition: background-color $easeDuration ease;

						&:hover {
							background-color: rgba(28, 21, 54, 0.55);
							border: none;
						}

						&.is-selected {
							border: none;
							background-color: #1c1536;
						}

						&:last-of-type {
							margin-right: 0;
						}
					}
				}
			}

			& .nav-filter-bar_year-filter-arrow {
				display: block;
				width: 0;
				height: 0;
				content: '';
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				border-bottom: 12px solid #525971;
				margin: auto;
				margin-top: 10px;
				position: relative;
				top: 1px;
			}
		}
	}
	
	& .nav-filter-bar_selected-indicators-description {
		float: right;
		width: 207px;
		height: 18px;
		margin-top: 14px;
		font-family: Steradian;
		font-size: 14px;
		line-height: 1.29;
		color: #ffffff;
		text-align: center;

		& .nav-filter-bar_selected-indicators-warning {
			margin-top: 6px;
			font-family: Steradian;
			font-size: 12px;
			line-height: 1.58;
			text-align: center;
			color: #b1bad5;
		}
	}

	@include breakpoint(small down) {
		margin: 0;
		& .nav-filter-bar_prompts {
			display: none;
		}

		& .nav-filter-bar_content {
			flex-direction: column-reverse;
			align-items: center;
			margin-top: 0;

			& .nav-filter-bar_display-options {
				margin-top: 25px;
				width: 100%;

				& .nav-filter-bar_display-option {
					width: 33.3333%;
					height: 33px;

					&:first-of-type {
						border-left: none;
					}

					&:last-of-type {
						border-right: none;
					}
				}
			}

			& .nav-filter-bar_filter {

				& .nav-filter-bar_metric-filter-button {
					width: 215px;
					margin-left: 9px;
					margin-right: 5px;
				}

				& .nav-filter-bar_year-filter-button {
					padding-right: 29px;
					margin-left: 3px;
				}

				& .nav-filter-bar_year-filter-contents {
					position: fixed;
					left: 0;
					right: 0;
					bottom: 0;
					top: 0;
					height: 100%;
					width: 100%;
					border-radius: 0;
					opacity: .97;
					padding: 28% 10% 0;
					white-space: nowrap;

					& .nav-filter-bar_choose-year {
						margin-top: 10px;
						margin-bottom: 26px;

						& button {
							width: 60px;
							height: 33px;
							margin-right: 7px;
							padding: 0;
							font-size: 15px;
							border-radius: 20px;

							&:hover {
								background-color: inherit;
								border: 1px solid #ffffff;
							}

							&.is-selected {
								background-color: #1c1536;
								border: none;
							}
						}
					}

					&.is-short {
						height: unset;
						padding-top: 28%;
					}

					& .nav-filter-bar_year-filter-contents_close {
						display: block;
						position: fixed;
						top: 0;
						right: 0;
						margin-top: 14px;

						& button {
							margin: 10px 20px 0 0;
							text-align: right;
							color: #ffffff;
							font-family: Plaak5Trial-45-A;
							font-size: 10px;
							font-weight: bold;
							letter-spacing: 1.25px;
							padding-right: 6px;
						}
					}
				}
	
				& .nav-filter-bar_year-filter-arrow {
					display: none;
				}

				&.is-comparing button.nav-filter-bar_year-filter-button {
					padding-right: 13px;

					&::before {
						margin-left: 13px;
						margin-right: 6px;
					}

					& span {

						&.is-small-screen {
							display: inline;
							font-size: 14px;
						}

						&.is-not-small-screen {
							display: none;
						}
					}
				}
			}
		}

		& .nav-filter-bar_selected-indicators-description {
			display: none;
		}
	}
}
