
.map {
  height: calc(100vh - 89px);
  display: flex;
	flex-direction: column;
	min-height: 616px;
	max-width: 1800px;
	margin: auto;
	position: relative;

  @media screen and (max-height: 400px) {
    height: 355px;
	}

  & .map_maps {
    margin: 0 85px;
    display: flex;
		flex: 1;
		min-height: 350px;
		transition: margin $offcanvas-transition-length ease;
		
		& .is-hidden {
			display: none;
		}

		& svg {
			flex: 1;
			margin: 0 20px 0;
			overflow: visible;

			& .maps_map-state {
				transition: all $easeDuration ease;
			}
		}

		& .maps_maps_not-compare {
			display: flex;
			flex: 1;
			margin: 0 75px 0 105px;
			transition: margin $offcanvas-transition-length ease;
		}

		& .maps_maps_compare {
			width: 50%;
			margin: 100px 40px 0;
			transition: margin $offcanvas-transition-length ease;
			display: flex;
			flex-direction: column;

			& svg {
				max-height: 65%;
			}

			& .maps_maps_compare_title {
				margin: 8vh auto 15px auto;
				@media only screen and (max-height: 800px) {
					margin-top: 2vh;
				}
				width: 141px;
				text-align: center;
				align-self: center;
				display: flex;

				& p {
					font-family: StyreneB;
					font-size: 36px;
					line-height: 0.89;
					color: #ffffff;
					margin: 0;
					padding-left: 15px; 
				}
				
				& img {
					height: 32px;
					margin-left: 10px;
					width: 14px;
				}
			}

			& .maps_maps_compare_details {
				display: flex;
				color: #ffffff;
				height: 32px;
				width: 85%;
				margin: 0 auto;
				justify-content: center;
					
				& span:first-child {
					font-family: StyreneB;
					font-size: 25px;
					line-height: 0.6;
					white-space: nowrap;
					align-self: center;
				}

				& span:last-child {
					text-align: left;
					font-family: Plaak5Trial-45-A;
					font-size: 12px;
					font-weight: bold;
					letter-spacing: 1.2px;
					vertical-align: top;
					margin-left: 7px;
					align-self: center;
				}
			}
		}

		& .maps_maps_scatter-plot {
			flex: 1;
			display: flex;
			width: 100%;
			height: 100%;
			margin: 5% 0 0 11%;

			@media screen and (max-height: 800px) {
				margin: 2% 0 0 5.5%;
			}

			& svg text {
				font-family: Steradian;
				font-size: 5px;
				text-align: center;
				pointer-events: none;
			}

			& .maps_maps_scatter-plot_axis-title {
				font-family: StyreneB;
				font-size: 11.5px;
				line-height: 1.33;
				text-align: center;
				fill: #ffffff;
			}

			& .maps_maps_scatter-plot_worsened-improved-title {
				font-family: Steradian;
				font-size: 5.7px;
				line-height: 1.58;
				letter-spacing: normal;
				fill: #b1bad5;
			}

			& .maps_maps_scatter-plot_average-title {
				font-family: Steradian;
				font-size: 4.6px;
				line-height: 1.4;
				letter-spacing: 0.22px;
				fill: #b1bad5;
			}
		}

		&.metric-drawer-is-opened {
			margin-right: 320px;

			& .maps_maps_not-compare {
				margin: 45px 30px 0;
			}
		}
  }
  
  & .map_contents {
    display: flex;
    min-height: 0;
    flex: 1;
	}

  & .map_legend {
    position: absolute;
    left: -39px;
    top: 39.4vh;
    bottom: 0;
    height: 12px;
    flex: 0;
    display: flex;
		transform: rotate(270deg);

		& .map_legend-tile {
			width: 12px;
			height: 11px;
			@each $value in $color-range {
				&.#{nth($value, 1)} {
					background-color: nth($value, 2);
				}
			}
		}

		& .map_legend-text {
			font-family: Plaak5Trial-45-A;
			font-size: 10px;
			font-weight: bold;
			letter-spacing: 1.25px;
			color: #ffffff;
			margin: 0 17px 0;
			align-self: center;

			&:first-child {
				// transform is changing margin for some reason, so forcing to correct
				margin-right: 15px;
			}
		}
	}

	& .map_footer {
		margin: 66px 101px 38px 100px;
		display: flex;
		justify-content: center;
		position: relative;
		top: 1px;
		align-items: flex-end;

		& span, & button {
			transition: opacity $easeDuration ease, color $easeDuration ease;
			z-index: 2;
		}

		& .is-invisible {
			opacity: 0;
			z-index: 1;
			cursor: default;
			pointer-events: none;
		}

		&.is-minimized {
			margin-top: 0;
		}

		& > span {
			flex: 1;
			display: flex;

			&.map_footer_toggle-visualization-wrapper {
				position: absolute;
				left: 0;
				bottom: 0;
				top: 0;

				& > span {
					position: absolute;
					left: 0;
					bottom: 0;

					&.map_footer_too-few-metrics-wrapper {
						flex-direction: column;
						justify-content: flex-end;
						display: flex;

						& .map_footer_too-few-metrics {
							width: 290px;
							font-family: Steradian;
							font-size: 13px;
							line-height: 1.46;
							color: #b1bad5;
							text-align: left;
							margin: 2px 0 12px;
						}
					}
				}
			}
		}

		& a, & button {
			color: #ffffff;

			&:focus,&:hover {
				color: #ffffff;
			}

			&.map_footer_select-state {
				border-bottom: solid 1px $activeSecondary;
				padding: 0 0 6px;
				display: block;
				margin: auto;
				font-family: Steradian;
				font-size: 13px;
				line-height: 1.38;
				position: relative;

				&:hover {
					color: $activeSecondary;
				}

				&::before {
					width: 0;
					height: 0;
					border: inset 6px;
					content: '';
					border-bottom-width: 0;
					border-top-style: solid;
					border-color: $activeSecondary transparent transparent;
					position: relative;
					top: 7px;
					float: left;
					margin-right: 9px;
				}

				&::after {
					content: '';
					width: 3px;
					height: 4px;
					position: absolute;
					bottom: -2px;
					left: -1px;
					background: $background;
				}
			}

			&.map_footer_toggle-visualization {
				white-space: nowrap;
				float: left;
				width: 125px;
				font-family: Plaak5Trial-45-A;
				font-size: 12px;
				font-weight: bold;
				line-height: 0.83;
				letter-spacing: 1.2px;
				align-self: flex-start;
				margin: 0;
				padding: 0;
				padding-bottom: 7px;
				text-align: left;

				&:hover {
					color: $active;
				}

				&:disabled {
					color: #b1bad5;
					opacity: 1;
				}
			}
		}
	}

	@include breakpoint(small down) {
		height: unset;
		min-height: unset;
		justify-content:flex-start;

		& .map_contents {
			flex-direction: column-reverse;
			flex: 0;
			margin: 8px 10px 0;
			min-height: 305px;

			& .maps_legend-wrapper {
				display: flex;
				justify-content: space-around;
				margin: 0;
				padding-top: 10px;

				& .maps_maps_details {
					display: flex;
					margin-right: 7%;
					margin-bottom: 7px;
					line-height: normal;
					max-height: 32px;
					
					& span:first-child {
						font-family: StyreneB;
						font-size: 20px;
						white-space: nowrap;
					}
					
					& span:last-child {
						text-align: left;
						font-family: Plaak5Trial-45-A;
						font-size: 10px;
						font-weight: bold;
						letter-spacing: 1px;
						margin-left: 7px;
						align-self: center;
						vertical-align: text-top;
					}
				}

				& .map_legend {
					margin-left: 1%;
					position: relative;
					transform: rotate(0deg);
					left: 0;
					right: 0;
					top: 0;
					align-self: center;

					& > div {
						font-size: 12px;
					}
				}

				&.is-comparing {
					display: none;
				}
			}
			

			& .map_maps {
				margin: 0;
				min-height: 0;				

				& .maps_maps_not-compare {
					margin: 0;

					& svg {
						flex: 0;
						height: 208px;
						margin: auto;
					}
				}

				& .maps_maps_compare {
					margin: 48px 0 0;

					& svg {
						flex: 0;
						height: 93px;
						margin: auto;
					}

					& .maps_maps_compare_title {
						font-size: 28px;
						margin: 17px 2px 0;
						display: flex;
						justify-content: center;
						width: 100%;
						height: 32px;

						& > p {
							font-size: 28px;
						}

						& > img {
							height: 24px;
						}
					}

					& .maps_maps_compare_details {
						margin-bottom: 16px;
						margin-top: -5px;

						& span:first-child {
							font-size: 20px;
						}

						& span:last-child {
							font-size: 10px;
							align-self: flex-start;
							padding-top: 9px;
						}
					}

					&.is-comparison-map {
						& .maps_maps_compare_details, & .maps_maps_compare_title {
							padding-right: 10px;
						}
					}
				}

				& .heatmap-compare-tooltip {
					display: none;
				}
			}
		}

		& .map_footer {
			margin: 0;
			flex: 1;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 0;

			& span {
				flex: 0;

				& button.map_footer_select-state {
					font-size: 16px;
					margin: 0 auto;
					padding-bottom: 4px;
					height: 28px;
	
					&:before {
						top: 10px;
					}
				}
			}

			& .map_footer-description {
				padding-top: 40px;
				font-family: Steradian;
				font-size: 16px;
				line-height: 1.5;
				color: #b1bad5;
				text-align: center;
				margin: 0 40px 60px;
			}

			& .map_footer-warning {
				width: 210px;
				font-family: Steradian;
				font-size: 12px;
				line-height: 1.58;
				text-align: center;
				color: #b1bad5;
				margin: 0 0 35px;
			}

			& .map_footer_toggle-visualization-wrapper {
				display: none;
			}
		}
	}
}
