.indicator-vs-nation {
	margin-bottom: 5px;
	width: 185px;

	& .indicator-vs-nation_label {
		font-family: 'Space Mono', monospace;
		font-weight: bold;
		line-height: 1.4;
		letter-spacing: 0.22px;
		font-size: 14.5px;
	}
	
	& .indicator-vs-nation_average {
		font-family: 'Space Mono', monospace;
		font-size: 21px;
		font-weight: bold;
		line-height: 1;
		letter-spacing: 0.31px;
		text-align: center;
	}
}

.state-profile-modal {
	& .indicator-vs-nation_label {
		color: #525971;
		fill: #525971;
	}
	
	& .indicator-vs-nation_average {
		color: #525971;
		fill: #525971;
	}
}

.indicators {
	& .indicator-vs-nation_label {
		color: #b1bad5;
		fill: #b1bad5;
	}
	
	& .indicator-vs-nation_average {
		color: #b1bad5;
		fill: #b1bad5;
	}
}
