.metrics-drawer {
  box-shadow: 0 2px 6px 2px #1c1536;
  background-color: #525971;
  display: flex;

  & .metrics-drawer_close-button {
    text-align: right;
    color: #ffffff;
    margin-top: 18px;
    margin-right: 5px;
    font-family: Plaak5Trial-45-A;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1.25px;
    padding-right: 6px;
    transition: color $easeDuration ease;

    &:hover {
      color: $active;
    }
  }

  & .metrics-drawer_content {
    padding-top: 42px;
    padding-right: 20px;
    margin: auto;
    overflow-x: hidden;

    & .metrics-drawer_title {
      font-family: StyreneB;
      font-size: 17px;
      line-height: 1.29;
      color: #ffffff;

      & img {
        margin-right: 9px;
        position: relative;
        top: -1px;
      }
    }

    & .metrics-drawer_all-none {
      display: flex;
      justify-content: center;
      margin: 16px 0 30px 9px;
      font-family: Plaak5Trial-45-A;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1.25px;
      color: #ffffff;
      align-items: center;

      & button {
        margin: 0;
        width: 46px;
        height: 25px;
        border-radius: 14px;
        border: solid 1px #ffffff;
        padding: 0;
        background-color: #525971;
        font-family: Steradian;
        font-size: 12px;
        line-height: 1.67;
        text-align: center;
        font-weight: normal;
        letter-spacing: normal;
        padding-bottom: 1px;
        transition: background-color $easeDuration ease;
        margin: 0 12px;

        &:hover {
          background-color: rgba(28, 21, 54, 0.55);
          border: none;
        }

        &.is-selected {
          border-color: #1c1536;
          background-color: #1c1536;
        }

        &.metrics-drawer_all-none_none {
          width: 49px;
          margin: 0;
        }
      }
    }

    & .metrics-drawer_metrics {
      text-align: left;
      margin-left: 25px;
      margin-bottom: 25px;


      & .metrics-drawer_metrics_group-title {
        font-family: Plaak5Trial-45-A;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.7;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        margin: 25px 0 15px 33px;
        width: 210px;
      }

      & .metrics-drawer_metrics_group {

        & .metrics-drawer_metrics_group_metric {
          align-items: center;
          display: flex;
          margin-bottom: 17px;
          position: relative;

          & input {
            margin: auto 0 auto -16px;
            cursor: pointer;
            top: 0;
            bottom: 0;
          }
          & label {
            font-family: Steradian;
            font-size: 11px;
            line-height: 1.42;
            color: #ffffff;
            cursor: inherit;
            height: initial;
            display: flex;
            align-items: center;
            margin-right: 15px;
          }
          & label:before {
            width: 18px;
            height: 18px;
            top: 0;
            bottom: 0;
            margin: auto 0 auto -24px;
            transition: background-color $easeDuration ease, border $easeDuration ease;
          }
          // override font-awesome
          &.checkbox {

            & input:hover {

              & + label:before {
                background-color: rgba(28, 21, 54, 0.55);
                border: none;
              }
            }

            & input + label:before {
              background-color :inherit;
              border: solid 1px #ffffff;
              margin-left: -24px;
            }

            & input:checked + label:before {
              background-color: #1c1536;
              border: none;
            }

            & input + label:after {
              content: '';
              left: 1px;
              top: 4px;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              transition: opacity $easeDuration ease;
            }

            & input:checked + label:after {
              content: url('../images/checkmark.svg');
            }

            & input:disabled {
              cursor: default;
            
                & + label{
                opacity: 1;
                color: #312b44;

                &:before {
                  background-color: inherit;
                  border: solid 1px #312b44;
                }

                &:after {
                  content: '';
                }
              }
            }
          }
        }
      }
    }

    & .metrics-drawer_learn-more {
      padding-bottom: 40px;

      & a {
        position: relative;
        font-family: Plaak5Trial-45-A;
        font-size: 11px;
        font-weight: bold;
        line-height: 2;
        letter-spacing: 1.38px;
        color: #ffffff;
        border-bottom: solid 1px $activeSecondary;
        padding-bottom: 6px;
        margin-left: 18px;
        transition: color $easeDuration ease;

        &:hover {
          color: $activeSecondary;
        }

        &::before {
          content: '';
          width: 1px;
          height: 4px;
          position: absolute;
          bottom: -2px;
          left: 0;
          background: #525971;
        }

        &::after {
          content: '';
          width: 2px;
          height: 4px;
          position: absolute;
          bottom: -2px;
          right: 0;
          background: #525971;
        }
      }
    }
  }

  @include breakpoint(small down) {
    transition: none;
    opacity: .97;
    padding-top: 49px;
    padding-right: 14px;

    & .metrics-drawer_close-button {
      margin-top: 14px;
    }
    & .metrics-drawer_content {

      & .metrics-drawer_title {
        font-family: StyreneB;
        font-size: 17px;
        line-height: 1.29;
        color: #ffffff;
      }

      & .metrics-drawer_all-none {
        margin: 19px 0 28px;
        font-size: 13.2px;
        justify-content: center;

        & button {
          width: 56px;
          height: 33px;
          margin: 0 10px;
          font-size: 14px;
          border-radius: 20px;

          &:hover {
            background-color: inherit;
            border: solid 1px #ffffff;;
          }
    
          &.is-selected {
            border-color: #1c1536;
            background-color: #1c1536;
          }

          &.metrics-drawer_all-none_none {
            width: 60px;
            margin: 0;
          }
        }
      }

      & .metrics-drawer_metrics {
        margin-left: 17%;
        margin-bottom: 59px;

        & .metrics-drawer_metrics_group-title {
          font-size: 12px;
        }

        & .metrics-drawer_metrics_group {
          & .metrics-drawer_metrics_group_metric {
            &.checkbox {

              & input + label {
                font-size: 13px;
  
                &::before, &::after {
                  height: 20px;
                  width: 20px;
                  margin-left: -26px;
                }
  
                &::after {
                  transform: scale(1.1);
                  margin-left: -28px;
  
                }
              }
  
              & input {
                height: 19px;
                width: 20px;
                margin-left: -18px;
              }

              & input:hover:not(:checked) {
                & + label:before {
                  background-color: inherit;
                  border: 1px solid #ffffff;
                }
              }
            }
          }
        }
      }

      & .metrics-drawer_learn-more {
        padding-bottom: 49px;
        & a {
          font-size: 12px;
          margin-left: 9px;
        }
      }
    }
  }
}

.tooltip.bottom.metrics-drawer_metrics_too-few-metrics-tooltip {
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(28, 21, 54, 0.61);
  font-family: Steradian;
  font-size: 13px;
  line-height: 1.46;
  text-align: center;
  color: #1c1536;
  width: 240px;
  height: 75px;
  max-width: initial;
  max-height: initial;
  padding-top: 19px;
  opacity: 1 !important;

  &::before {
    border-bottom-width: .7rem;
  }

  // need to override zurb, that sneaky devil
  &.is-invisible {
    opacity: 0 !important;
  }
}
