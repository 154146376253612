.app-failure {
  height: calc(100vh - 89px);
  padding-bottom: 79px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.is-without-description {
    padding-bottom: 107px;
  }
  
  & .app-failure_title {
    font-family: StyreneB;
    font-size: 48px;
    text-align: center;
    color: #ffffff;
  }

  & .app-failure_description {
    font-family: Plaak5Trial-45-A;
    font-size: 14px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 1.75px;
    text-align: center;
    color: #ffffff;
    margin-top: 16px;
  }

  & a {
    font-family: Steradian;
    font-size: 13px;
    line-height: 1.38;
    text-align: center;
    color: #ffffff;
    padding: 0 0 6px;
    border-bottom: 1px solid $activeSecondary;
    margin-top: 45px;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      bottom: -1px;
      width: 2px;
      height: 2px;
      background-color: $background;
    }
  }

  & button.button.clear {
    font-family: Plaak5Trial-45-A;
    font-size: 14px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 1.75px;
    text-align: center;
    color: #ffffff;
    padding: 0 0 2px;
    border-bottom: 1px solid $activeSecondary;
    margin-top: 40px;
    text-transform: uppercase;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      bottom: -1px;
      width: 2px;
      height: 2px;
      background-color: $background;
    }

    &::after {
      position: absolute;
      content: '';
      right: 0;
      bottom: -1px;
      width: 2px;
      height: 2px;
      background-color: $background;
    }
  }

  @include breakpoint(small down) {
    height: calc(100vh - 89px);

    & .app-failure_title {
      width: 75%;
      line-height: 1.22;
    }

    & .app-failure_description {
      width: 70%;
    }

    & .app-failure_title {
      font-size: 36px;
    }
  
    & .app-failure_description {
      font-family: Plaak5Trial-45-A;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.64;
      letter-spacing: 1.64px;
      text-align: center;
      color: #ffffff;
      margin-top: 16px;
    }

    & button.button.clear {
      margin-top: 50px;
    }
  }
}