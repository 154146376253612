.landing-content {
	display: flex;
	flex-direction: column;
	align-items: center;

	& .landing-content_above-updates {
		padding-top: 109px;
		background-color: #ffffff;
		width: 100%;

		& .landing-content_spotlight {
			width: 824px;
			margin: 0 auto;
			font-family: StyreneB;
			font-size: 35px;
			line-height: 1.49;
			letter-spacing: 0.12px;
			color: #00143c;
		}
	
		& .landing-content_about-button {
			width: 111px;
			display: block;
			margin: 32px auto 106px;
			font-family: Plaak5Trial-45-A;
			font-size: 14px;
			font-weight: bold;
			line-height: 1.57;
			letter-spacing: 1.75px;
			color: #1c1536;
			padding-bottom: 8px;
			border-bottom: solid 1.5px $active;
			transition: color $easeDuration ease;
	
			&:hover {
				color: $active;
			}
		}
	}

	& .landing-content_updates {
		display: flex;
		width: 100%;
		justify-content: center;
		padding-bottom: 66px;
		text-align: left;
		background: linear-gradient(to bottom, #ffffff 122px, #e4e4e1 122px);

		& .card {
			flex: 0 0 396px;
			width: 396px;
			margin: 0 16px 0;
			border: none;
			transition: background-color $easeDuration ease;

			& img {
				height: 244px;
			}

			& .card-section {
				padding: 24px 41px 16px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				& h6 {
					font-family: 'Space Mono', monospaces;
					font-size: 12px;
					font-weight: bold;
					line-height: 2;
					color: $active;
					margin-bottom: 5px;
					transition: color $easeDuration ease;
				}
	
				& h4 {
					font-family: StyreneB;
					font-size: 20px;
					line-height: 1.5;
					color: #1c1536;
					margin: 0 0 12px 0;
					transition: color $easeDuration ease;
				}
	
				& p > p {
					font-family: Steradian;
					font-size: 14px;
					line-height: 1.43;
					color: #1c1536;
					margin: 0 0 55px 0;
					transition: color $easeDuration ease;
				}
	
				& .landing-content_updates_author {
					font-family: Steradian;
					font-size: 12px;
					font-weight: 500;
					line-height: 3.17;
					color: #525971;
					transition: color $easeDuration ease;
				}
			}

			&:hover {
				cursor: pointer;
				background-color: $active;

				& h6, & h4, & p > p, & .landing-content_updates_author {
					color: #ffffff;
				}
			}
		}
	}

	& .landing-content_below-updates {
		background-color: #e4e4e1;
		width: 100%;

		& .landing-content_more-articles {
			display: block;
			width: 160px;
			font-family: Plaak5Trial-45-A;
			font-size: 14px;
			font-weight: bold;
			line-height: 1.57;
			letter-spacing: 1.75px;
			color: #1c1536;
			padding-bottom: 9px;
			border-bottom: solid 1.5px $active;
			transition: color $easeDuration ease;
			margin: 0 auto 84px;

			&:hover {
				color: $active;
			}
		}
	}

	@include breakpoint(small down) {
		background: unset; 

		& .landing-content_above-updates {
			padding-top: 54px;

			& .landing-content_spotlight {
				width: unset;
				height: unset;
				font-size: 24px;
				margin: 0 20px;
			}

			& .landing-content_about-button {
				margin-bottom: 0;
				padding-bottom: 9px;
				font-size: 16px;
				margin-top: 27px;
				letter-spacing: 2px;
				width: 126px;
			}
		}

		& .landing-content_updates {
			flex-direction: column;
			height: unset;
			padding: 60px 33px 0;
			background: linear-gradient(to bottom, #ffffff 167.5px, #e4e4e1 167.5px);

			& .card {
				width: 100%;
				margin: 10px 0 43px;
				flex: 1;

				& img {
					height: 195px;
				}

				& .card-section {
					padding: 23px 30px 9px 33px;

					& h6 {
						font-size: 11px;
						margin-bottom: 1px;
					}

					& p > p {
						margin-bottom: 44px;
					}

					& .landing-content_updates_author {
						font-size: 11px;
						display: block;
    				margin-bottom: 8px;
					}
				}

				&:hover {
					background-color: #ffffff;
	
					& h4, & p > p {
						color: #1c1536;
					}

					& .landing-content_updates_author {
						color: #525971;
					}

					& h6 {
						color: $active;
					}
				}
			}
		}

		& .landing-content_below-updates {
			& .landing-content_more-articles {
				width: 183px;
				font-size: 16px;
				letter-spacing: 2px;
				margin: 9px auto 64px;
			}
		}
	}
}