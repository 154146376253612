.state-profile-modal {
  padding: 0;
  margin: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(242, 244, 246, 0.96);
  transition: opacity $easeDuration ease;

  &.force-fade {
    opacity: 0;
  }

  &.raised {
    z-index: 10000;
  }

  & > div {

    & .state-profile-modal_wrapper {
      min-width: 1200px;
      position: relative;
      padding: 110px 0 0 0;
      color: #1c1536;
      max-width: 1550px;
      margin: auto;

      & .state-profile-modal_close-button {
        text-align: right;
        margin-top: 57px;
        margin-right: 83px;
        font-family: Plaak5Trial-45-A;
        font-size: 12px;
        font-weight: bold;
        line-height: 0.83;
        letter-spacing: 1.2px;

        & .is-desktop {
          width: 73px;
          height: 14px;
          text-align: left;
          line-height: 13px;
          color: #1c1536;
          transition: color $easeDuration ease;
          display: flex;

          & img {
            position: absolute;
            right: 0;
            margin-left: 21px;
            transition: opacity $easeDuration ease;

            &.is-hovered {
              opacity: 0;
            }
          }
        }

        &:hover {
          & .is-desktop {
            color: $active;

            & img {
              &.is-hovered {
                opacity: 1;
              }
            }
          }
        }
      }

      & .state-profile-modal_header {
        padding: 0 8.1% 0 8.7%;
        display: flex;
        justify-content: space-between;

        & .state-profile-modal_title-section {
          font-family: StyreneB;
          font-size: 32px;
          width: 48.3%;

          & .state-profile-modal_title {
            font-size: 60px;
            margin-bottom: 22px;
            line-height: 1.1;
            margin-top: 12px;
          }

          & > button {
            color: #1c1536;
            width: unset;
            background: inherit;
            border: none;
            font-size: 32px;
            margin: 0;
            padding: 0 0 0 15px;
            vertical-align: baseline;
            color: unset;
            transition: unset;
            transition: color $easeDuration ease;

            &::after {
              width: 0;
              height: 0;
              border: inset 6px;
              content: '';
              border-bottom-width: 0;
              border-top-style: solid;
              border-color: #1c1536 transparent transparent;
              position: relative;
              top: 16px;
              float: right;
              margin-left: 13px;
              transition: border-color $easeDuration ease;
            }

            &:hover, &.hover {
              color: $active;

              &::after {
                border-color: $active transparent transparent;
              }
            }
          }

          & .state-profile-modal_select-year-dropdown {
            width: auto;
            background: inherit;
            padding: 0;
            border: none;
            opacity: 0;
            display: block;
            transition: opacity $easeDuration ease, visibility $easeDuration ease;
            &.is-open {
              opacity: 1;
            }

            & .state-profile-modal_select-year-dropdown-arrow {
              display: block;
              width: 0;
              height: 0;
              content: '';
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-bottom: 12px solid #ffffff;
              margin: auto;
              margin-top: 5px;
              position: relative;
              top: 1px;
            }

            & .state-profile-modal_select-year-dropdown-contents {
              background: #ffffff;
              font-size: 32px;
              overflow-y: auto;
              box-shadow: 0 0 6px 0 rgba(5, 18, 67, 0.2);

              & > button {
                margin: 19px 22px;
                display: block;
                transition: color $easeDuration ease;
                cursor: pointer;
      
                &:hover, &.is-selected {
                  color: $active;
                }
              }
            }
          }

          & .state-profile-modal_epi-average {
            margin-top: 34px;
            font-family: StyreneB;
            font-size: 25px;
            display: flex;

            & .state-profile-modal_epi-average-description {
              font-family: Plaak5Trial-45-A;
              font-size: 12px;
              font-weight: bold;
              line-height: 3;
              margin-left: 10px;
              letter-spacing: 1.2px;
            }
          }

          & .state-profile-modal_other-years {
            display: flex;
            font-family: Steradian;
            font-size: 10px;
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: 0.22px;

            & .state-profile-modal_sparkline {
              margin-right: 7px;
            }
          }

          & .state-profile-modal_share {
            margin-top: 46px;
            margin-bottom: 123px;
            font-family: Plaak5Trial-45-A;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1.5px;
            display: flex;
            align-items: center;

            & .state-profile-modal_share_copy-confirm {
              font-family: Steradian;
              font-size: 10px;
              font-weight: 500;
              line-height: 1.6;
              color: #525971;
              margin-left: 13px;
              letter-spacing: normal;        
              opacity: 0;
              transition: opacity $easeDuration ease;
              
              &.is-visible {
                opacity: 1
              }
            }
    
            & a, & button {
              cursor: pointer;
              margin-left: 11px;
              display: block;
              position: relative;
              width: 24px;
              height: 24px;

              & img {
                transition: opacity $easeDuration ease;
                position: absolute;
                left: 0;
                top: 0;
                width: 24px;
                height: 24px;

                &.is-hover {
                  opacity: 0;
                }
              }

              &:hover {
                & img {
                  &.is-hover {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }

        & .state-profile-modal_header-info {
          display: flex;
          justify-content: stretch;
          flex: 1;

          & .state-profile-modal_header-info-section {
            margin-top: 45px;
            border-top: 1px solid $active;
            padding-top: 12px;
            margin-left: 5.5%;
            width: 50%;
      
            & .state-profile-modal_header-info-section_title {
              font-family: Plaak5Trial-45-A;
              font-size: 12px;
              font-weight: bold;
              line-height: 1.33;
              letter-spacing: 0.86px;
            }

            & .state-profile-modal_header-info-section_datapoint {
              font-family: StyreneB;
              margin-top: 16px;

              &:first-of-type {
                margin-top: 30px;
              }

              & div:first-child {
                font-size: 24px;
                color: rgba(0, 0, 0, 0.85);

                & img {
                  margin-left: 5px;
                }
              }
              & div:last-child {
                font-size: 10px;
                font-weight: 500;
                line-height: 1.6;
                letter-spacing: 0.71px;
                width: 55%;
                font-family: Steradian;
                text-transform: uppercase;
                margin-top: 6px;
              }
            }

            & .state-profile-modal_header-info-section_condition {
              margin-top: 25px;
              margin-left: -1px;
              &:first-of-type {
                margin-top: 37px;
              }
              &:last-of-type {
                margin-top: 22px;
              }

              & div:first-child {
                font-family: Plaak5Trial-45-A;
                font-size: 10px;
                font-weight: bold;
                line-height: 1.6;
                letter-spacing: 0.71px;
                color: #525971;
                margin-bottom: 12px;
              }

              & div:last-child {
                font-family: Steradian;
                font-size: 16px;
                line-height: 1.5;
                color: rgba(0, 0, 0, 0.85);

                & a {
                  margin-top: 4px;
                  color: #000000;
                  text-decoration: underline;
                  font-size: 12px;
                  display: block;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }

      & .state-profile-modal_indicators {
        padding: 0 7.7% 0 7%;

        & .state-profile-modal_indicators-title {
          font-family: Plaak5Trial-45-A;
          font-size: 12px;
          font-weight: bold;
          line-height: 1.33;
          letter-spacing: 0.86px;
          margin-bottom: 39px;
          margin-left: 2%;
        }
        
        & .state-profile-modal_indicators-group {

          & .state-profile-modal_indicators-group-title {
            margin: 0 2% 33px;
            border-top: solid 1px $active;
            padding-top: 25px;
            font-family: StyreneB;
            font-size: 20px;
            line-height: 0.8;
            color: rgba(0, 0, 0, 0.85);
          }

          &:first-of-type {
            & .state-profile-modal_indicators-group-title {
              margin-bottom: 25px;
            }
          }

          & .grid-x {
            & .medium-6 {
              & .state-profile-indicator_description {
                width: 85%;
              }
            }

            & .state-profile-indicator {
              margin: 0 0 42px;
              padding: 23px 2% 20px;
              cursor: pointer;
              color: #1c1536;
              transition: all $easeDuration ease;

              &:hover {
                box-shadow: 0 0 6px 0 rgba(5, 18, 67, 0.2);
                background-color: #ffffff;
              }

              & .state-profile-indicator_value {
                font-size: 24px;
                font-family: StyreneB;
                margin-bottom: 3px;
                transition: min-height $easeDuration ease;
              }
            
              & .state-profile-indicator_name {
                font-size: 10px;
                font-weight: 500;
                line-height: 1.6;
                letter-spacing: 0.71px;
                font-family: Steradian;
                margin-bottom: 16px;
                text-transform: uppercase;
                width: 90%;
                transition: min-height $easeDuration ease;
              }
            
              & .state-profile-indicator_description {
                font-size: 13px;
                line-height: 1.38;
                color: #525971;
                font-family: Steradian;
                margin-bottom: 26px;
                transition: min-height $easeDuration ease;
              }
            
              & .state-profile-indicator_visual-title {
                font-family: Plaak5Trial-45-A;
                font-size: 10px;
                font-weight: bold;
                line-height: 1.6;
                letter-spacing: 0.71px;
                color: #525971;
              }
            
              & .state-profile-indicator_relative-to-average {
                font-family: Steradian;
                font-size: 10px;
                font-weight: 500;
                line-height: 1.6;
                color: #525971;
                width: 160px;

                & > div {
                  margin-bottom: 1em;
                }
              }
            }

            & .state-profile-indicator-not-available {
              padding: 23px 0 0 .4%;
              background-color: rgba(82, 89, 113, 0.1);
              margin: 0 1% 42px 1.8%;
              width: 22%;
              display: flex;
              cursor: pointer;
              min-height: 334px;

              & > div {
                padding-left: 5px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                width: 80%;

                & .state-profile-indicator-not-available_title {
                  font-family: StyreneB;
                  font-size: 24px;
                  color: #525971;
                  text-align: left;
                  white-space: nowrap;
                  transition: min-height $easeDuration ease;
                }
    
                & .state-profile-indicator-not-available_name {
                  font-family: Steradian;
                  font-size: 10px;
                  font-weight: 500;
                  line-height: 1.6;
                  letter-spacing: 0.71px;
                  color: #525971;
                  text-align: left;
                  text-transform: uppercase;
                  margin-top: 3px;
                  transition: min-height $easeDuration ease;
                }
    
                & .state-profile-indicator-not-available_description {
                  font-family: Steradian;
                  font-size: 13px;
                  line-height: 1.6;
                  color: #525971;
                  text-align: left;
                  margin-top: 16px;
                }
              }
            }
          }
        }
      }
    }

    & .state-profile-modal_footer {
      height: 118px;
      background-color: #525971;
      display: flex;
      padding: 0 3px 2px 0;
      margin-top: 35px;
      min-width: 1200px;

      & .state-profile-modal_footer_select-state {
        border-bottom: solid 1px $activeSecondary;
        padding: 0 3px 4px 0;
        display: block;
        margin: auto;
        font-family: Steradian;
        font-size: 16px;
        line-height: 1.38;
        position: relative;
        color: #ffffff;
        height: 28px;

        &:hover {
          color: $activeSecondary;
        }

        &::before {
          width: 0;
          height: 0;
          border: inset 6px;
          content: '';
          border-bottom-width: 0;
          border-top-style: solid;
          border-color: $activeSecondary transparent transparent;
          position: relative;
          top: 10px;
          float: left;
          margin-right: 9px;
        }

        &::after {
          content: '';
          width: 1px;
          height: 4px;
          position: absolute;
          bottom: -2px;
          left: -1px;
          background: #525971;
        }
      }
    }
  }

  @include breakpoint(small down) {
    & > div {

      & .state-profile-modal_wrapper {
        min-width: unset;
        padding: 61px 0 0;

        & .state-profile-modal_close-button {
          margin: 16px 8px 0 0;
        }
    
        & .state-profile-modal_header {
          padding: 0 6.5% 0 7.3%;
          flex-direction: column;
          height: unset;
    
          & .state-profile-modal_title-section {
            width: unset;
            font-size: 24px;
    
            & .state-profile-modal_title {
              font-size: 34px;
              margin-bottom: 13px;
              margin-left: -5px;
              margin-top: unset;
            }
    
            & > button {
              font-size: 24px;
              padding: 0 0 0 10px;

              &::after {
                top: 10.5px;
                margin-left: 9px;
              }
            }

            & .state-profile-modal_share {
              margin-bottom: 0;

              & a, & button { 
                margin-left: 10px;
                
                &:first-child {
                  margin-left: 17px;
                }

                &:hover img.is-hover {
                  opacity: 0;
                }
              }
            }

            & .state-profile-modal_select-year-dropdown {
              & .state-profile-modal_select-year-dropdown-contents {
                font-size: 24px;
                padding-bottom: 5px;

                & > button {
                  margin: 19px 33px;
                }
              }
            }
          }

          & .state-profile-modal_header-info {

            & .state-profile-modal_header-info-section {
              &:first-child {
                margin: 40px 5px 0 0;
              }
              &:last-child {
                margin: 40px 0 0 12px;
              }

              & .state-profile-modal_header-info-section_datapoint {
                margin-top: 21px;

                &:first-of-type {
                  margin-top: 14px;
                }

                & > div:first-child {
                  font-size: 18px;
                }

                & > div:last-child {
                  margin-top: 10px;
                  width: 85%;
                }
              }

              & .state-profile-modal_header-info-section_condition {
                margin-left: 0;
                margin-top: 21px;

                &:first-of-type {
                  margin-top: 20px;
                }

                &:last-of-type {
                  margin-top: 27px;
                }

                & > div:first-child {
                  margin-bottom: 11px;
                }

                & > div:last-child {
                  font-size: 14px;

                  & a {
                    font-size: 10px;
                    margin-top: 0px;
                    line-height: 2;              
                  }
                }
              }
              
            }
          }
        }
    
        & .state-profile-modal_indicators {
          padding: 56px 0 0;

          & .state-profile-modal_indicators-title {
            margin: 0 8% 37px;
          }

          & .state-profile-modal_indicators-group {

            & .state-profile-modal_indicators-group-title {
              margin: 0 6% 27px 7.7%;
              padding-top: 17px;
              line-height: 1.4;
            }

            & .grid-x {
              margin-bottom: 48px;

              & .state-profile-indicator {
                padding: 32px 7.5% 9px;
                backdrop-filter: blur(3px);
                background-color: rgba(215, 217, 221, 0.96);
                margin-bottom: 12px;

                &:hover {
                  box-shadow: unset;
                  background-color: rgba(215, 217, 221, 0.95);
                }

                & .state-profile-indicator_value {
                  min-height: 0 !important; // override inline style on desktop
                }

                & .state-profile-indicator_name {
                  min-height: 0 !important; // override inline style on desktop
                }

                & .state-profile-indicator_description {
                  min-height: 0 !important; // override inline style on desktop
                  width: 100%;
                  margin-bottom: 9px;

                  & p {
                    line-height: 1.5;
                  }
                }

                & .state-profile-indicator_visual {
                  padding-bottom: 13px;
                  margin-bottom: 0;
                }

                & .state-profile-indicator_relative-to-average {
                  margin-bottom: 33px;
                }
              }

              & .state-profile-indicator-not-available {
                padding: 32px 7.5% 27px;
                width: 100%;
                margin: 0 0 12px 0;
                min-height: unset;

                & > div {
                  padding-left: unset;
                  margin: unset;

                  & .state-profile-indicator-not-available_title {
                    min-height: 0 !important; // override inline style on desktop
                  }

                  & .state-profile-indicator-not-available_name {
                    min-height: 0 !important; // override inline style on desktop
                  }

                  & .state-profile-indicator-not-available_description {
                    width: unset;
                    margin-bottom: 9px;
                  }
                }
              }
            }
          }
        }
      }

      & .state-profile-modal_footer {
        position: absolute;
        top: 28px;
        left: 21px;
        background-color: inherit;
        height: unset;
        margin-top: unset;
        min-width: unset;

        & .state-profile-modal_footer_select-state {
          color: #1c1536;
          height: unset;
          font-family: Steradian;
          font-size: 14px;
          line-height: 1.57;
          border: none;
          &:hover {
            color: #1c1536;
          }
          &::before, &::after {
            display: none;
          }
          & img {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
