.single-indicator-visual {
	display: flex;
	flex-direction: column;

	& .single-indicator-visual_visualization_legend {
		display: flex;
		justify-content: space-between;
		font-family: Plaak5Trial-45-A;
		font-size: 10px;
		font-weight: bold;
		letter-spacing: 1.25px;
	}

	& .single-indicator-visual_bars {
		height: 38px;
		display: flex;
		justify-content: stretch;
		margin-bottom: 18px;
		align-items: flex-end;
		position: relative;

		& .single-indicator-visual_bar-wrapper {
			width: 2%;
			padding-left: 2px;
			cursor: pointer;

			& .single-indicator-visual_bar-background-overlay {
				opacity: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				pointer-events: none;
				z-index: 2;
				transition: opacity $easeDuration ease;
			}

			& .single-indicator-visual_bar-tooltip {
				bottom: 51px;
				left: 0;
				right: 0;
				opacity: 0;
				pointer-events: none;
				position: absolute;
				font-family: Steradian;
				font-weight: 500;
				font-size: 14px;
				line-height: 1;
				letter-spacing: 0.31px;
				text-align: center;
				width: 100%;
				transition: opacity $easeDuration ease;
			}

			& .single-indicator-visual_bar {
				height: 100%;
				z-index: 1;
				position: relative;
			}

			&:hover {

				& .single-indicator-visual_bar {
					height: 100%;
					z-index: 3;
				}

				& .single-indicator-visual_bar-background-overlay {
					opacity: .4;
				}

				& .single-indicator-visual_bar-tooltip {
					opacity: 1;
				}
			}
		}
	}
}

.indicators {
	& .single-indicator-visual_visualization_legend {
		color: #ffffff;
	}
	& .single-indicator-visual_bar-background-overlay {
		background-color: #525971;
	}
	& .single-indicator-visual_bar-tooltip {
		color: #ffffff;
	}
}

.indicator-profile-modal {
	& .single-indicator-visual_visualization_legend {
		color: #1c1536;
	}
	& .single-indicator-visual_bar-background-overlay {
		background-color: rgba(242, 244, 246, .6);
	}
	& .single-indicator-visual_bar-tooltip {
		color: #1c1536;
	}
}
