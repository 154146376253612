
.heatmap-compare-tooltip {
	color: #ffffff;
	font-family: StyreneB;
	width: 400px;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	padding-top: 10px;
	min-width: $minAppWidthDesktop;

	& .heatmap-tooltip_title {
		font-family: StyreneB;
		font-size: 20px;
	}
}

.heatmap-tooltip {
	color: #ffffff;
	flex-direction: column;
	text-align: center;
	font-family: StyreneB;
	position: absolute;
	z-index: 10;
	pointer-events: none;
	visibility: hidden;
	width: 400px;
	display: flex;
	opacity: 0;
	bottom: -100px;
	transition: visibility $easeDuration ease, opacity $easeDuration ease;

	& .heatmap-tooltip_title {
		font-family: StyreneB;
		font-size: 35px;
		line-height: 1;
		margin-bottom: 13px;
	}

	& .heatmap-tooltip_percentage {
		display: flex;
		align-self: center;
		padding-right: 5px;
		margin-bottom: 4px;

		& .heatmap-tooltip_percentage_value {
			font-family: StyreneB;
			font-size: 25px;
			line-height: 1;
			margin-bottom: 3px;
		}
	
		& .heatmap-tooltip_percentage_description {
			font-family: Plaak5Trial-45-A;
			font-size: 12px;
			font-weight: bold;
			line-height: 2;
			letter-spacing: 1.2px;
			margin-left: 9px;
		}
	}

	& .heatmap-tooltip_other-years {
		display: flex;
		align-self: center;

		& .heatmap-tooltip_other-years_sparkline {
			margin-right: 11px;
			height: 30px;
			width: 44px;
		}

		& .heatmap-tooltip_other-years_values {
			font-family: Steradian;
			font-size: 10px;
			font-weight: 500;
			line-height: 1.4;
			letter-spacing: 0.22px;
			color: #c4f0ff;
			text-align: left;
		}
	}

	@include breakpoint(small down) {
		display: none;
	}
}
