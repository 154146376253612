.indicator-profile-modal {
  margin: 0;
  padding: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(242, 244, 246, 0.96);
  transition: opacity $easeDuration ease;

  &.force-fade {
    opacity: 0;
  }

  & > div {

    & .indicator-profile-modal_wrapper {
      padding: 147px 0 0 0;
      color: #1c1536;
      min-width: 1000px;
      overflow-x: hidden;
      position: relative;
      max-width: 1550px;
      margin: auto;

      & .indicator-profile-modal_select-another {
        color: #1c1536;
        font-family: Steradian;
        font-size: 14px;
        line-height: 1.57;
        border: none;
        position: absolute;
        top: 28px;
        left: 21px;

        &:hover {
          color: #1c1536;
        }
        &::before, &::after {
          display: none;
        }
        & img {
          margin-right: 8px;
        }
      }

      & .indicator-profile-modal_close-button {
        text-align: right;
        margin-top: 57px;
        margin-right: 83px;
        font-family: Plaak5Trial-45-A;
        font-size: 12px;
        font-weight: bold;
        line-height: 0.83;
        letter-spacing: 1.2px;
        color: #1c1536;
    
        & .is-desktop {
          width: 73px;
          height: 14px;
          text-align: left;
          line-height: 13px;
          transition: color $easeDuration ease;
          display: flex;

          & img {
            position: absolute;
            right: 0;
            margin-left: 21px;
            transition: opacity $easeDuration ease;

            &.is-hovered {
              opacity: 0;
            }
          }
        }

        &:hover {
          & .is-desktop {
            color: $active;

            & img {
              &.is-hovered {
                opacity: 1;
              }
            }
          }
        }
      }
    
      & .indicator-profile-modal_header {
        padding: 0 107px 0 103px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 67px;
    
        & .indicator-profile-modal_title-section {
          font-family: StyreneB;
          font-size: 32px;
          width: 53.5%;
          
    
          & .indicator-profile-modal_title {
            font-size: 38px;
            margin-bottom: 26px;
            line-height: 38px;
          }
    
          & > button {
            color: #1c1536;
            width: unset;
            background: inherit;
            border: none;
            font-size: 32px;
            margin: 0;
            padding: 0 0 0 15px;
            color: unset;
            transition: unset;
            vertical-align: baseline;
            transition: color $easeDuration ease;
    
            &::after {
              width: 0;
              height: 0;
              border: inset 6px;
              content: '';
              border-bottom-width: 0;
              border-top-style: solid;
              border-color: #1c1536 transparent transparent;
              position: relative;
              top: 16px;
              float: right;
              margin-left: 13px;
              transition: border-color $easeDuration ease;
            }
    
            &:hover, &.hover {
              color: $active;
    
              &::after {
                border-color: $active transparent transparent;
              }
            }
          }
    
          & .indicator-profile-modal_select-year-dropdown {
            width: auto;
            background: inherit;
            padding: 0;
            border: none;
            opacity: 0;
            display: block;
            transition: opacity $easeDuration ease, visibility $easeDuration ease;
            &.is-open {
              opacity: 1;
            }
    
            & .indicator-profile-modal_select-year-dropdown-arrow {
              display: block;
              width: 0;
              height: 0;
              content: '';
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-bottom: 12px solid #ffffff;
              margin: auto;
              margin-top: 5px;
              position: relative;
              top: 1px;
            }
    
            & .indicator-profile-modal_select-year-dropdown-contents {
              background: #ffffff;
              font-size: 32px;
              overflow-y: auto;
              box-shadow: 0 0 6px 0 rgba(5, 18, 67, 0.2);
    
              & > button {
                margin: 19px 22px;
                display: block;
                transition: color $easeDuration ease;
                cursor: pointer;
      
                &:hover, &.is-selected {
                  color: $active;
                }
              }
            }
          }
    
          & .indicator-profile-modal_title-description {
            font-family: Steradian;
            font-size: 16px;
            line-height: 1.5;
            color: rgba(0, 0, 0, 0.85);
            margin-top: 43px;
            width: 67%;
          }
    
          & .indicator-profile-modal_average {
            margin-top: 45px;
            font-family: StyreneB;
            font-size: 25px;
            color: #1c1536;
            margin-bottom: 37px;
    
            & .indicator-profile-modal_average-description {
              margin-top: 5px;
              font-family: Plaak5Trial-45-A;
              font-size: 12px;
              font-weight: bold;
              line-height: 0.83;
              letter-spacing: 1.2px;
              color: #1c1536;
            }
          }
    
          & .indicator-profile-modal_other-years {
            display: flex;
            font-family: Steradian;
            font-size: 10px;
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: 0.22px;
    
            & .indicator-profile-modal_sparkline {
              margin-right: 7px;
            }
          }
    
          & .indicator-profile-modal_share {
            margin-top: 23px;
            margin-left: 2px;
            font-family: Plaak5Trial-45-A;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1.5px;
            display: flex;
            align-items: center;

            & .indicator-profile-modal_share_copy-confirm {
              font-family: Steradian;
              font-size: 10px;
              font-weight: 500;
              line-height: 1.6;
              color: #525971;
              opacity: 0;
              margin-left: 13px;
              letter-spacing: normal;        
              transition: opacity $easeDuration ease;
              
              &.is-visible {
                opacity: 1
              }
            }
    
            & a, & button {
              cursor: pointer;
              margin-left: 11px;
              display: block;
              position: relative;
              width: 24px;
              height: 24px;

              & img {
                transition: opacity $easeDuration ease;
                position: absolute;
                left: 0;
                top: 0;
                width: 24px;
                height: 24px;

                &.is-hover {
                  opacity: 0;
                }
              }

              &:hover {
                & img {
                  &.is-hover {
                    opacity: 1;
                  }
                }
              }
            }
    
            & a:first-child {
              margin-left: 18px;
            }
          }
        }
    
        & .indicator-profile-modal_visualizations-section {
          flex: 1;
          display: flex;
          flex-direction: column;
    
          & .indicator-profile-modal_map-visual {
            padding-bottom: 60px;
            flex: 1;
    
            & svg {
              overflow: visible;
            }
          }
    
          & .indicator-profile-modal_bar-visual {
            width: 94%;
            margin-left: 1.4%;
            margin-top: 1.4%;
          }
    
          & .indicator-profile-modal_no-visual {
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-family: Steradian;
            font-size: 13px;
            color: #525971;
            line-height: 1.38;
            margin-top: 6px;
            margin-right: 12px;
          }
        }
      }
    
      & .indicator-profile-modal_contents {
        margin: 0 107px 110px 103px;
        padding-top: 45px;
        border-top: solid 1px $active;
        display: flex;
        font-family: Steradian;
    
        & .indicator-profile-modal_contents_description {
          font-size: 16px;
          color: #1c1536;
          margin-right: 30px;
          width: 57%;

          & a {
            color: #1c1536;
            text-decoration: underline;
          }
    
          & p {
            line-height: 1.5;
            margin-bottom: 12px;
          }
        }
    
        & .indicator-profile-modal_contents_notes {
          font-size: 14px;
          color: #525971;
          margin-right: 130px;
          flex: 1;
          line-height: 1.43;

          & a {
            color: #525971;
            text-decoration: underline;
          }
    
          & ul {
            margin-left: 0;
            list-style-type: none;
            margin-bottom: 10px;
    
            li {
              line-height: 1.43;
              margin-top: 10px;
            }
          }
        }
      }
    }
  
    & .indicator-profile-modal_footer {
      height: 195px;
      background-color: #f3f3f3;

      & .indicator-profile-modal_footer-content {
        max-width: 1550px;
        padding: 56px 157px 0;
        margin: auto;
        display: flex;
        justify-content: space-between;
    
        & .indicator-profile-modal_footer_next, & .indicator-profile-modal_footer_previous  {
    
          & > div:first-of-type {
            font-family: Plaak5Trial-45-A;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 1.25px;
            color: #1c1536;
          }
    
          & > div:last-of-type {
            font-family: Steradian;
            font-size: 16px;
            line-height: 1.5;
            color: #1c1536;
            position: relative;
            height: 74px;
            width: 200px;
            display: flex;
            align-items: center;
    
            & .indicator-profile-modal_footer_arrow {
              position: absolute;
              top: 0;
              bottom: 6px;
              margin: auto;
              width: 30px;
              height: 30px;

              & img {
                cursor: pointer;
                position: absolute;
                left: 0;

                &.is-hover {
                  opacity: 0;
                  transition: opacity $easeDuration ease;
                  
                  &:hover {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
    
        & .indicator-profile-modal_footer_next {
          text-align: right;

          & > div:last-of-type {
            justify-content: flex-end;
      
            & .indicator-profile-modal_footer_arrow {
              right: -55px;
            }
          }
        }
    
        & .indicator-profile-modal_footer_previous {
          text-align: left;

          & > div:last-of-type {
            justify-content: flex-start;
    
            & .indicator-profile-modal_footer_arrow {
              left: -55px;
            }
          }
        }
      }
    }
  }

  @include breakpoint(small down) {
    & > div {

      & .indicator-profile-modal_wrapper {
        padding: 71px 22px 0;
        min-width: unset;

        & .indicator-profile-modal_close-button {
          margin: 16px 8px 0 0;
        }

        & .indicator-profile-modal_header {
          flex-direction: column;
          padding: 0;
          margin-bottom: 71px;

          & .indicator-profile-modal_title-section {
            width: unset;
            font-size: 24px;

            & .indicator-profile-modal_title {
              font-size: 28px;
              margin-bottom: 14px;
              line-height: 36px;
            }

            & > span {
              padding-left: 3px;
            }

            & > button {
              font-size: 24px;
              padding: 0 0 0 10px;

              &::after {
                top: 10.5px;
                margin-left: 9px;
              }
            }

            & .indicator-profile-modal_title-description {
              margin-top: 18px;
              width: 90%;
            }

            & .indicator-profile-modal_average {
              margin: 38px 0 13px;
              display: flex;
              align-items: center;

              & .indicator-profile-modal_average-description {
                margin-top: 4px;
                padding: 0 15px 2px;
              }
            }

            & .indicator-profile-modal_share {
              margin: 48px 0 37px;

              & a:hover, & button:hover { 
                & img.is-hover {
                  opacity: 0;
                }
              }
            }

            & .indicator-profile-modal_select-year-dropdown {
              & .indicator-profile-modal_select-year-dropdown-contents {
                font-size: 24px;
                padding-bottom: 5px;

                & > button {
                  margin: 19px 33px;
                }
              }
            }
          }

          & .indicator-profile-modal_visualizations-section {
            width: unset;

            & .indicator-profile-modal_map-visual {
              padding-bottom: 33px;

              & svg {
                margin: 0 6px 0 10px;
              }
            }

            & .indicator-profile-modal_map_legend {
              height: 12px;
              flex: 0;
              display: flex;
              justify-content: center;
              padding-right: 8px;
          
              & .indicator-profile-modal_map_legend-tile {
                width: 12px;
                height: 11px;
                @each $value in $color-range {
                  &.#{nth($value, 1)} {
                    background-color: nth($value, 2);
                  }
                }
              }
          
              & .indicator-profile-modal_map_legend-text {
                font-family: Plaak5Trial-45-A;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 1.25px;
                color: #1c1536;
                margin: 0 17px 0;
                align-self: center;
          
                &:first-child {
                  // transform is changing margin for some reason, so forcing to correct
                  margin-right: 15px;
                }
              }
            }
          }
        }
        
        & .indicator-profile-modal_contents {
          flex-direction: column;
          margin: 0;
          padding-top: 28px;
          width: 95%;

          & .indicator-profile-modal_contents_description, & .indicator-profile-modal_contents_notes {
            margin-right: unset;
            width: unset;
          }

          & .indicator-profile-modal_contents_description {
            margin-bottom: 11px;
          }

          & .indicator-profile-modal_contents_notes {
            margin-bottom: 15px;
          }
        }
      }

      & .indicator-profile-modal_footer {
        background: inherit;
        height: unset;

        & .indicator-profile-modal_footer-content {
          padding: 0 22px 43px;

          & .indicator-profile-modal_footer_previous, & .indicator-profile-modal_footer_next {
            width: 50%;

            & > div:last-of-type {
              height: unset;
              width: unset;
              display: flex;
              flex-direction: column-reverse;

              & > span {
                font-size: 14px;
                line-height: 1.43;
              }

              & > div {
                font-family: Plaak5Trial-45-A;
                font-size: 10px;
                font-weight: bold;
                letter-spacing: 1.25px;
                margin: 18px 0 13px;
              }

              & .indicator-profile-modal_footer_arrow {
                position: unset;
                margin: unset;

                & img {
                  position: unset;
                  margin: unset;
                }
              }
            }
          }

          & .indicator-profile-modal_footer_previous {
            margin-right: 4px;

            & > div:last-of-type {
              float: left;
              align-items: flex-start;
            }
          }

          & .indicator-profile-modal_footer_next {
            margin-right: 7px;

            & > div:last-of-type {
              float: right;
              align-items: flex-end;
            }
          }
        }
      }
    }
  }
}
